<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Businesses', route: 'Admin_Businesses' },
        { name: 'Update Business', route: 'Admin_Businesses' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="warning" v-for="warning in warnings" :key="warning">
            {{ warning }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="UpdateBusiness">
            <label for="form.name">Business / Practice Name</label>
            <input type="text" class="input" v-model="form.name" required />

            <!-- Display label for incorrect country -->
            <div v-if="dataLoaded && showInvalidCountry" class="invalid-country-info">
              <label for="invalid-country">Original Country Typed by User (Please fix this by selecting a valid country):</label>
              <input
                type="text"
                id="invalid-country"
                class="input"
                :value="originalCountry"
                disabled
              />
            </div>

            <label for="country">Country</label>
            <select
              name="form.country"
              id="form.country"
              class="input"
              v-model="form.country"
              @change="onCountryChange"
              required
            >            
              <option value="">-- Please Choose a Country --</option>
              <option v-for="country in countryOptions" :key="country" :value="country">
                {{ country }}
              </option>              
            </select>

            <label for="form.postcode">Postcode</label>
            <input
              type="text"
              class="input"
              v-model="form.postcode"
              @input="onPostcodeInput"
              required
            />

            <label for="form.address">Address</label>
            <textarea
              class="input left"
              v-model="form.address"
              @input="onAddressInput"
              @focus="showSuggestions = true"
              @blur="hideSuggestions"
              rows="4"
              required
            ></textarea>
            <ul v-if="showSuggestions && suggestions.length > 0" class="suggestions">
              <li v-for="suggestion in suggestions" :key="suggestion.id" @mousedown.prevent="selectSuggestion(suggestion)">
                {{ suggestion.summaryline }}, {{ suggestion.locationsummary }}
              </li>
            </ul>

            <label for="form.contact_email">Contact Email</label>
            <input
              type="email"
              class="input"
              v-model="form.contact_email"
              required
            />
            <label for="form.contact_telephone">Contact Telephone</label>
            <input
              type="tel"
              class="input"
              v-model="form.contact_telephone"
              required
            />

            <label for="form.infinite_credits">Unlimited Test Credits</label>
            <select
              name="form.infinite_credits"
              id="form.infinite_credits"
              class="input"
              v-model="form.infinite_credits"
              @change="toggleTestCreditsFields"
            >
              <option value="false">No</option>
              <option value="true">***Yes***</option>
            </select>

            <div v-if="form.infinite_credits === 'true'">
              <label for="form.activationdate">Test Credits Activation Date</label>
              <input
                type="date"
                class="input"
                v-model="form.activationdate"
                placeholder="Activation Date"
                @change="updateExpiryDate"
                required
              />

              <label for="form.expirydate">Test Credits Expiry Date</label>
              <input
                type="date"
                class="input"
                v-model="form.expirydate"
                placeholder="Expiry date"
                required
              />
            </div>

            <div v-if="form.infinite_credits === 'false'">
              <label for="form.credits">Test Credits</label>
              <input
                type="number"
                class="input"
                v-model="form.credits"
                min="0"
                required
              />
            </div>
            <div class="row">
              <div class="col-4">
                <img :src="form.logo" />
              </div>
              <div class="col-8">
                <label for="form.logo">Logo / Badge</label>
                <input
                  type="file"
                  class="input"
                  accept="image/*"
                  @change="logoUpload"
                />
              </div>
            </div>
            <label for="form.organisation">Organisation</label>
            <select
              name="form.organisationid"
              id="form.organisationid"
              class="input"
              v-model="form.organisationid"
            >
              <option value="null">Independant</option>
              <option
                :value="org.id"
                v-for="org in organisations"
                :key="org.id"
              >
                {{ org.name }}
              </option>
            </select>

            <label for="form.mkit">MicronKit Number</label>
            <input
              type="character"
              class="input"
              v-model="form.mkit"
              required
            />
            <br />
            <br />
            <label for="form.fluke_kit">Fluke Kit Purchased?</label>
            <select
              name="form.fluke_kit"
              id="form.fluke_kit"
              class="input"
              v-model="form.fluke_kit"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>

            <div v-if="form.fluke_kit === 'true'">
              <label for="form.flukekitnumber">Fluke Kit Number</label>
              <input
                type="text"
                class="input"
                v-model="form.flukekitnumber"
                required
              />
            </div>

            <label for="form.businessstatus">Business Status</label>
            <select
              name="form.businessstatus"
              id="form.businessstatus"
              class="input"
              v-model="form.businessstatus"
            >
              <option value="Active">Active</option>
              <option value="Archived">Archived</option>
              <option value="Inactive">Inactive</option>
            </select>
            <br>
            <br>
            <div v-if="form.businessstatus !='Active'">
              <label for="form.businessstatusnote">Business Status Note</label>
              <textarea
              class="input left"
              v-model="form.businessstatusnote"
              rows="4"
            ></textarea>

            </div>

            <div v-if="businessTypeLabel">
              <label for="form.businesstype">Business Type</label>
              <input
                type="text"
                class="input"
                :value="businessTypeLabel"
                readonly
              />
            </div>


            <br />
            <br />
            <input type="submit" class="btn btn-green" value="Save Changes" />
          </form>
        </div>
        <div class="col-6">
          <div v-if="form.organisation !== null">
            <div class="info">
              This business is part of the {{ form.orgname }} organisation.
            </div>
          </div>
          <br />
          <div class="avail-test-placeholder">
            <div class="tests">{{ form.credits }}</div>
            <div class="title">Available tests</div>
          </div>
          <br />
          <table class="table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td colspan="2">Role</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="1">
                  <label for="adduser.email">Email Address</label>
                  <input
                    id="adduser.email"
                    type="email"
                    class="input"
                    v-model="adduser.email"
                    placeholder="example@example.com"
                  />
                </td>
                <td>
                  <label for="adduser.role">Role</label>
                  <select
                    class="input"
                    v-model="adduser.role"
                    id="adduser.role"
                  >
                    <option value="1">Member</option>
                    <option value="2">Lead Member</option>
                    <option value="3">Manager</option>
                    <option value="5">Veterinarian</option>
                    <option value="6">Veterinary Nurse</option>
                    <option value="7">Administrative Staff</option>
                    <option value="8">Practice Manager</option>
                    <option value="9">Store Manager</option>
                    <option value="10">Agricultural Supplier</option>
                    <option value="11">Sales Manager</option>
                  </select>
                </td>
                <td colspan="2">
                  <button class="btn btn-green" v-on:click="addUserToBusiness">
                    Invite User <i class="icon icon-pen"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(user, index) in users" :key="user">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ roleTypes(user.role) }}</td>
                <td>
                  <div
                    class="btn btn-orange"
                    v-on:click="RemoveUserFromBusiness(user.user_id, index)"
                  >
                    <span class="icon icon-delete"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="detailedReport !== null">
        <div class="col-12">
          <table>
            <thead>
              <tr>
                <td>Business</td>
                <td>Organisation</td>
                <td>Total Tests Remaining</td>
                <td>Total Tests Attempted</td>
                <td>Total Tests Confirmed</td>
                <td>Total Tests Failed</td>
                <td>Date of Last Test</td>
                <td>Days Since Last Test</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ detailedReport.businessname }}</td>
                <td>{{ detailedReport.organisationname || "Independant" }}</td>
                <td>{{ detailedReport.credits }}</td>
                <td>{{ detailedReport.tests_used }}</td>
                <td>
                  {{ detailedReport.tests_used - detailedReport.tests_failed }}
                </td>
                <td>{{ detailedReport.tests_failed }}</td>
                <td>{{ TimeParse(detailedReport.last_testdate) }}</td>
                <td>
                  {{
                    detailedReport && detailedReport?.days?.days
                      ? detailedReport.days.days
                      : "N/A"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      messages: "",
      errors: "",
      warnings: "",
      showSuggestions: false,
      suggestions: [],
      dataLoaded: false,
      form: {
        name: "",
        country: "",
        postcode: "",
        address: "",
        contact_email: "",
        contact_telephone: "",
        owner: "",
        mkit: "",
        fluke_kit: "false",
        flukekitnumber: null,
        savedCredits: "",
        savedActivationDate: "",
        savedExpiryDate: "",
        infinite_credits: "false",
        activationdate: "",
        expirydate: "",
        credits: "",
        organisationid: null,
        businessstatus:"",
        businessstatusnote:"",
        businesstype:""
      },
      users: [],
      adduser: {
        email: "",
        role: 1,
      },
      organisations: null,
      detailedReport: null,
      originalCountry: "",
      countryOptions: [ 
        "Belgium",
        "England, UK",
        "France",
        "Germany",
        "Luxembourg",
        "Netherlands",
        "Northern Ireland, UK",
        "Republic of Ireland",
        "Romania",
        "Scotland, UK",
        "Wales, UK",
      ],
    };
  },
  components: {
    Navigation,
  },
  methods: {
    GetBusiness() {
      this.store
        .dispatch("Admin/GetBusinessInfo", this.$route.params.id)
        .then((result) => {
         console.log("Result from get function - ",result.data);
          this.form = result.data;
          this.users = result.users;
          this.form.organisationid = this.form.organisation;
          this.form.activationdate = this.form.activationdate ? this.form.activationdate.substr(0, 10) : "";
          this.form.expirydate = this.form.expirydate ? this.form.expirydate.substr(0, 10) : "";
          this.form.fluke_kit = result.data.fluke_kit ? "true" : "false";
          this.form.flukekitnumber = result.data.flukekitnumber;
          this.form.businessstatus = result.data.status;   
          this.form.businessstatusnote = result.data.statusnote;
          this.form.businesstype = result.data.type;
          
          this.originalCountry = (result.data.country ?? "Empty").trim();          
          if (!this.countryOptions.includes(this.originalCountry)) {
            this.form.country = "";
          } else {
            this.form.country = this.originalCountry;
          }

          if (this.form.infinite_credits === true) {
            this.form.infinite_credits = "true";
            this.form.savedCredits = this.form.credits;
            this.form.credits = null;
          } else {
            this.form.infinite_credits = "false";
            this.form.savedActivationDate = this.form.activationdate;
            this.form.savedExpiryDate = this.form.expirydate;
            this.form.activationdate = null;
            this.form.expirydate = null;
          }
          this.dataLoaded = true;
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    logoUpload(e) {
      this.form.change_logo = true;
      this.form.logo = e.target.files[0];
    },
    UpdateBusiness() {
      this.form.id = this.$route.params.id;
      const dataToSubmit = {
        ...this.form,
        credits: this.form.infinite_credits === "false" ? this.form.credits : null,
        activationdate: this.form.infinite_credits === "true" ? this.form.activationdate : null,
        expirydate: this.form.infinite_credits === "true" ? this.form.expirydate : null,
        flukekitnumber: this.form.fluke_kit === "true" ? this.form.flukekitnumber : null,
      };

      this.store
        .dispatch("Admin/UpdateBusinessInfo", dataToSubmit)
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
          this.originalCountry = this.form.country;
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    onCountryChange() {      
      if (this.form.country) {        
        if (!this.form.postcode && !this.form.address) {
          this.warnings = [];
        }
      } else {   
        this.form.country = "";
        this.warnings = [];
      }
    },

    onPostcodeInput() {      
      if (this.form.postcode && !this.form.country) {
        this.warnings = ["Please select a country to lookup the address automatically."];
      } else if (this.form.country && this.form.postcode) {
        this.warnings = [];
        const country = this.form.country;
        const postcode = this.form.postcode.replace(/\s+/g, "");

        const postcodeLengths = {
          "England, UK": [5, 6, 7, 8],
          "Northern Ireland, UK": [5, 6, 7, 8],
          "Scotland, UK": [5, 6, 7, 8],
          "Wales, UK": [5, 6, 7, 8],
          "New Zealand": [4],
          "Belgium": [4],
          "France": [5],
          "Luxembourg": [4],
          "Netherlands": [6],
          "Republic of Ireland": [7],
          "Romania": [6],
          "Germany": [5],
        };

        const validLengths = postcodeLengths[country];
        if (validLengths && validLengths.includes(postcode.length)) {
          this.lookupAddress(postcode, country); 
        }
      }
    },      
    onAddressInput() {      
      if (this.form.address.length >= 3 && !this.form.country) {
        this.warnings = ["Please select a country to autocomplete the address."];
      } else if (this.form.address.length >= 3 && this.form.country) {
        this.warnings = [];
        this.autocompleteAddress(this.form.address, this.form.country); 
      } else {
        this.suggestions = [];
      }
    },
    autocompleteAddress(input, country) {
      this.store
        .dispatch("Admin/AutocompleteAddress", { input, country })
        .then((response) => {
          this.suggestions = response;
        })
        .catch((error) => {
          console.error("Error fetching autocomplete suggestions:", error);
          this.suggestions = [];
        });
    },
    selectSuggestion(suggestion) {
      this.form.address = suggestion.summaryline;
      this.suggestions = [];
      this.retrieveFullAddress(suggestion.id, suggestion.summaryline, this.form.country);
    },
    lookupAddress(postcode, country) {
      this.store
        .dispatch("Admin/LookupAddressByPostcode", { postcode, country })
        .then((response) => {
          const addressData = response.data;          
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address = `${addressData[0].addressline1}\n${addressData[0].addressline2}`;
          } else {
            this.warnings = ["No address found for the given postcode. Please enter the address manually."];
            this.form.address = ""; 
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
          this.warnings = ["Error fetching address. Please enter the address manually."];
          this.form.address = ""; 
        });
    },
  
    retrieveFullAddress(id, input, country) {
      this.store
        .dispatch("Admin/RetrieveFullAddress", { id, input, country })
        .then((response) => {
          const addressData = response;
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address = `${addressData[0].addressline1}\n${addressData[0].addressline2}`;
            this.form.postcode = addressData[0].postcode; // Update the postcode field
          } else {
            this.warnings = ["No full address found for the selected suggestion."];
            this.form.address = "";
          }
        })
        .catch((error) => {
          console.error("Error retrieving full address:", error);
          this.warnings = ["Error retrieving full address. Please enter the address manually."];
          this.form.address = "";
        });
    },
    hideSuggestions() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },
    toggleTestCreditsFields() {
      if (this.form.infinite_credits === "true") {        
        this.form.savedCredits = this.form.credits;
        this.form.credits = null;        
        this.form.activationdate = this.form.savedActivationDate;
        this.form.expirydate = this.form.savedExpiryDate;
      } else {       
        this.form.savedActivationDate = this.form.activationdate;
        this.form.savedExpiryDate = this.form.expirydate;
        this.form.activationdate = null;
        this.form.expirydate = null;
        this.form.credits = this.form.savedCredits;
      }
    },
    roleTypes(int) {
      switch (int) {
        case 1:
          return "Member";
        case 2:
          return "Lead Member";
        case 3:
          return "Manager";
        case 4:
          return "Owner";
        case 5:
          return "Veterinarian";
        case 6:
          return "Veterinary Nurse";
        case 7:
          return "Administrative Staff";
        case 8:
          return "Practice Manager";
        case 9:
          return "Store Manager";
        case 10:
          return "Agricultural Supplier";
        case 11:
          return "Sales Manager";
        
        default:
          return "N/A";
      }
    },
    addUserToBusiness() {
      if (this.adduser.email !== "" && this.adduser.role !== "") {
        this.store
          .dispatch("Admin/AddUserToBusiness", {
            id: this.$route.params.id,
            email: this.adduser.email,
            role: this.adduser.role,
          })
          .then((result) => {
            this.messages = result.messages;
            this.errors = "";
          })
          .catch((err) => {
            this.errors = err.messages;
            this.messages = "";
            console.log(err);
          });
      }
    },
    RemoveUserFromBusiness(user, index) {
      this.store
        .dispatch("Admin/RemoveUserFromBusiness", {
          id: this.$route.params.id,
          user_id: user,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
          this.users.splice(index, 1);
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
        });
    },
    GetOrganisations() {
      this.store.dispatch("Admin/GetListOfOrganisations", null).then((data) => {
        this.organisations = data;
      });
    },
    GetDetailedReport() {
      this.store
        .dispatch("Admin/GetBusinessDetailedReport", {
          id: this.$route.params.id,
        })
        .then((result) => {
          this.detailedReport = result.data;
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
        });
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    updateExpiryDate() {
      if (this.form.activationdate) {
        const activationDate = new Date(this.form.activationdate);
        const expiryDate = new Date(activationDate.getFullYear() + 1, activationDate.getMonth(), activationDate.getDate());
        this.form.expirydate = expiryDate.toISOString().slice(0, 10);
      }
    }
  
  },
  computed: {
    businessTypeLabel() {
      const businessTypeMapping = {
        Vetstore: "VET/ Farm Store",
        Equine: "Equine",
      };
      return businessTypeMapping[this.form.businesstype] || "";
    },
    showInvalidCountry() {      
      return (
        (!this.countryOptions.includes(this.form.country) && this.originalCountry) || 
        this.form.country === ""
      );
    },
  },
  mounted() {
    this.GetBusiness();
    this.GetOrganisations();
    this.GetDetailedReport();
  },
};
</script>

<style>
.warning {
  height: auto;
  width: 100%;
  background: #eb8146;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #ffb248;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}

.invalid-country-info {
  margin-bottom: 0px;
  padding: 0px 8px;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 10px;
  color: #842029;
}
</style>
